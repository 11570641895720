import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      ... on Success {
        ok
      }
      ... on LoginErrors {
        accessToken
      }
    }
  }
`;
//Never called from here, used in apollo/refreshToken
export const REFRESH_MUTATION = gql`
  mutation Refresh($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      ... on Success {
        id
        email
      }
      ... on RefreshErrors {
        invalid
      }
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation RegisterMutation($input: RegisterInput!) {
    register(input: $input) {
      ... on Success {
        ok
      }
      ... on RegisterErrors {
        confirmPassword
        email
        name
        password
      }
    }
  }
`;
