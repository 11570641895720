import Assemblies from "./Assemblies";
import Dashboard from "./Dashboard";
import Landing from "./Landing";
import Assembly from "./Assembly";
import Upload from "./Upload";
import Report from "./Report";

export {
  Assemblies,
  Assembly,
  Dashboard,
  Landing,
  Upload,
  Report,
};