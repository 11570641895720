import React, { useState } from "react";
import { Modal, CardHeader, TextField, Select, MenuItem, Button, Grid, FormControl, InputLabel, Card, CardActions, CardContent } from "@mui/material";
import { Box } from "@mui/material";
import { useMutation } from "@apollo/client";
import { ADD_PRODUCT, CREATE_ASSEMBLY } from "gql/mutations";
import { materialValues, materials, productProperties } from "./materials";

const CreateComponent = ({ assemblyIds, setAssemblyIds }: { assemblyIds: any; setAssemblyIds: any; }) => {
  const [productName, setProductName] = useState("");
  const [productMaterial, setProductMaterial] = useState(materials[0].name);
  const [productHeight, setProductHeight] = useState("");
  const [productWidth, setProductWidth] = useState("");
  const [productThickness, setProductThickness] = useState("");

  const [createAssembly] = useMutation(CREATE_ASSEMBLY);

  const handleAddProduct = () => {
    const variables = {
      input: {
        name: productName,
        material: productMaterial,
        height: parseFloat(productHeight),
        width: parseFloat(productWidth),
        thickness: parseFloat(productThickness),
      },
    };
    createAssembly({ variables }).then((result) => {
      const left = result.data.createAssembly.id;
      setAssemblyIds({ left });
      return createAssembly({ variables });
    }).then((result) => {
      const right = result.data.createAssembly.id;
      setAssemblyIds((prevIds: any) => ({
        ...prevIds,
        right: right,
      }));
    });

  };

  return (
    <Card style={{ width: "70%", margin: "0 auto" }}>
      <CardHeader title="Create Component" />
      <CardContent>
        <div className="form-container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Product Name"
                fullWidth
                variant="outlined"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="material-label">Material Type</InputLabel>
                <Select
                  labelId="material-label"
                  label="Material Type"
                  value={productMaterial}
                  onChange={(e) => setProductMaterial(e.target.value)}
                >
                  {materials.map((material) => (
                    <MenuItem key={material.name} value={material.name}>
                      {material.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Height (m)"
                fullWidth
                variant="outlined"
                value={productHeight}
                onChange={(e) => setProductHeight(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Width (m)"
                fullWidth
                variant="outlined"
                value={productWidth}
                onChange={(e) => setProductWidth(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Thickness (m)"
                fullWidth
                variant="outlined"
                value={productThickness}
                onChange={(e) => setProductThickness(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      </CardContent>
      <CardActions>
        <Button onClick={handleAddProduct} variant="contained" color="primary">
          Add Component
        </Button>
      </CardActions>
    </Card>
  );
};

export default CreateComponent;
