import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { GET_ASSEMBLY_TREE } from "gql/queries";
import { useQuery } from "@apollo/client";
import { LinearProgress } from "@mui/material";

const AssemblyComparisonChart = ({ left, right }: { left: string, right: string }) => {
  const chartRef = useRef(null);
  const [chartReady, setChartReady] = React.useState(false);


  // Fetch the left assembly
  const {
    data: leftData,
    loading: leftLoading,
    error: leftError,
  } = useQuery(GET_ASSEMBLY_TREE, {
    variables: { getAssemblyId: left },
  });

  // Fetch the right assembly
  const {
    data: rightData,
    loading: rightLoading,
    error: rightError,
  } = useQuery(GET_ASSEMBLY_TREE, {
    variables: { getAssemblyId: right },
  });

  useEffect(() => {
    // Check if both data are loaded and there's no error
    if (!leftLoading && !rightLoading && !leftError && !rightError && leftData && rightData) {
      setChartReady(true);
      console.log("setting chart ready true");
    } else {
      setChartReady(false);
      console.log("setting chart ready false");
    }
  }, [leftLoading, rightLoading, leftError, rightError, leftData, rightData]);

  useEffect(() => {
    if (!chartReady && !chartRef.current) {
      console.log("running");
      return;
    };

    // Assuming the query returns a similar structure as the mock function
    const dataLeft = leftData.getAssemblyTree.assembly.components;
    const dataRight = rightData.getAssemblyTree.assembly.components;

    console.log("dataLeft");
    console.log(dataLeft);
    console.log("dataRight");
    console.log(dataRight);

    const combinedData: { [key: string]: any } = [...dataLeft, ...dataRight].reduce((acc: { [key: string]: any }, curr) => {
      const material = curr.product.material;
      if (!acc[material]) {
        acc[material] = { material, [left]: 0, [right]: 0 };
      }
      acc[material][left] += dataLeft.find((item: any) => item.product.material === material)?.carbonCost || 0;
      acc[material][right] += dataRight.find((item: any) => item.product.material === material)?.carbonCost || 0;
      return acc;
    }, {});

    console.log("combinedData");
    console.log(combinedData);

    const chartData = Object.values(combinedData);

    if (chartRef.current) {
      const root = am5.Root.new(chartRef.current);

      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      const chart = root.container.children.push(am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
      }));

      // Create Y-axis
      const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "material",
        renderer: am5xy.AxisRendererY.new(root, {}),
      }));

      yAxis.data.setAll(chartData);

      // Create X-axis with stacking enabled
      const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        // Enable stacking here
        extraTooltipPrecision: 0
      }));
      xAxis.set("numberFormat", "#a"); // Adjust format as necessary

      // Adjust the createSeries function accordingly
      const createSeries = (field: any, name: string) => {
        const series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: field,
          categoryYField: "material",
          sequencedInterpolation: true,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueX}"
          })
        }));
        series.data.setAll(chartData);
        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
        // Stacking is enabled via the axis, so no need to set it on the series
      };

      console.log("left");
      console.log(left);

      createSeries(left, "Left Assembly");
      createSeries(right, "Right Assembly");

      // Add legend
      const legend = chart.children.push(am5.Legend.new(root, {}));
      legend.data.setAll(chart.series.values);

      // Cleanup
      return () => {
        root.dispose();
      };
    }
  }, [chartReady, leftData, rightData]);

  // Handle loading state
  if (leftLoading || rightLoading) return <LinearProgress />;
  // Handle error state
  if (leftError) return <div>Error loading left assembly: {leftError.message}</div>;
  if (rightError) return <div>Error loading right assembly: {rightError.message}</div>;

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }}></div>;
};

export default AssemblyComparisonChart;
