import React from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useReactiveVar } from "@apollo/client";
import { authenticatedUserVar } from "apollo/cache";

const PrivateRoute: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { instance } = useMsal();
  const history = useHistory();
  const user = useReactiveVar(authenticatedUserVar);

  React.useEffect(() => {
    if (!user) {
      const accounts = instance.getAllAccounts();
      if (accounts && accounts.length > 0) {
        const user = accounts[0];
        instance.setActiveAccount(user);
        authenticatedUserVar(user);
      }
      else {
        history.push("/");
      }
    }
  }, [instance, user]);

  if (!Component) return null;
  return <Route {...rest} render={(props) => {
    return (
      <AuthenticatedTemplate>
        <Component {...props} />
      </AuthenticatedTemplate>
    );
  }} />;
};

export default PrivateRoute;
