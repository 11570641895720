import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

import ScrollTree from "./ScrollTree";
import CreateComponent from "./CreateModal";
import MaterialPieChart from "./reports/ByMaterial";
import AssemblyComparisonChart from "./reports/Stacked";


const AssemblyPage = () => {
  // Checking if assembly IDs are set
  const [assemblyIds, setAssemblyIds] = React.useState<{ left: string; right: string }>({
    left: "",
    right: "",
  });
  const [hasAssemblyIds, setHasAssemblyIds] = React.useState<boolean>(false);

  React.useEffect(() => {
    setHasAssemblyIds(!!assemblyIds.left && !!assemblyIds.right);
  }, [assemblyIds]);

  return (
    <div>
      {hasAssemblyIds ? (
        <Grid container spacing={2} padding={16}>
          <Grid item xs={6}>
            <Typography variant="h4">Left Assembly</Typography>
            <ScrollTree assemblyId={assemblyIds.left} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">Right Assembly</Typography>
            <ScrollTree assemblyId={assemblyIds.right} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">Left Assembly</Typography>
            <MaterialPieChart assemblyId={assemblyIds.left} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">Right Assembly</Typography>
            <MaterialPieChart assemblyId={assemblyIds.right} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Comparison</Typography>
            <AssemblyComparisonChart left={assemblyIds.left} right={assemblyIds.right} />
          </Grid>
        </Grid>
      ) : (
        <CreateComponent assemblyIds={assemblyIds} setAssemblyIds={setAssemblyIds} />
      )}
    </div>
  );
};

export default AssemblyPage;