import { onError } from "@apollo/client/link/error";
import browserHistory from "browserHistory";
import { clearClient } from "apollo/client";
import msalInstance from "azure/msal";

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(({graphQLErrors, networkError }) => {
  let adminError = false;
  let authError = false;
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions, message, path }) => {
      if (extensions.code === "UNAUTHENTICATED") {
        authError = true;
      }
      else if (extensions.code === "FORBIDDEN") {
        adminError = true;
      }
      else {
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
      }
    });
  if (networkError) console.error(`[Network error]: ${networkError}`);
  if (adminError) {
    if (process.env.NODE_ENV === "development")
      console.error("User is not an admin, redirecting to profile page...");
    browserHistory.push("/profile");
  }
  if (authError) {
    if (process.env.NODE_ENV === "development")
      console.error("User is not authenticated, redirecting to login page...");
    msalInstance.logoutRedirect();
  }
});
