// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,700);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link {
  text-decoration: none;
}

.map-container {
  margin: auto;
  width: 100%;
  height: 85vh;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;
  border-radius: 4px;
}

.sidebar-input {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  font-family: monospace;
  border:0
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;AACd;;AAEA;EACE,uCAAuC;EACvC,WAAW;EACX,iBAAiB;EACjB,sBAAsB;EACtB,UAAU;EACV,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,uCAAuC;EACvC,WAAW;EACX,sBAAsB;EACtB;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700');\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\"\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\na:link {\n  text-decoration: none;\n}\n\n.map-container {\n  margin: auto;\n  width: 100%;\n  height: 85vh;\n}\n\n.sidebar {\n  background-color: rgba(35, 55, 75, 0.9);\n  color: #fff;\n  padding: 6px 12px;\n  font-family: monospace;\n  z-index: 1;\n  position: absolute;\n  top: 0;\n  right: 0;\n  margin: 16px;\n  border-radius: 4px;\n}\n\n.sidebar-input {\n  background-color: rgba(35, 55, 75, 0.9);\n  color: #fff;\n  font-family: monospace;\n  border:0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
