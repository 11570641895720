import React from "react";
import "./TreeNode.css";
import ComponentCard from "./ComponentCard";
import { Box } from "@mui/material";

const TreeNode = ({
  node,
  components,
  assemblyId,
  depth,
  addChild,
}: {
  node: any;
  components: any[];
  assemblyId: string;
  depth: number;
  addChild: (assemblyId: string, componentId: string) => void;
}) => {
  const childrenWidth = node.children ? 100 / node.children.length : 100;

  const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

  return (
    <Box
      sx={{
        padding: "16px",
        height: "100%",
      }}
      className="node"
    >
      <div className="card">
        <ComponentCard
          component={components.find((c) => c.id === node.id)}
          assemblyId={assemblyId}
          addChild={addChild}
          depth={depth}
        />
      </div>
      {node.children && (
        <div>
          {node.children.map((child: any) => (
            <div key={child.id}>
              <TreeNode
                node={child}
                components={components}
                assemblyId={assemblyId}
                addChild={addChild}
                depth={depth + 1}
              />
            </div>
          ))}
        </div>
      )}
    </Box>
  );
};

export default TreeNode;