import { gql } from "@apollo/client";

export const GET_ALL_PRESIGNED_URLS = gql`
  query GetAllBlobs {
    getAllBlobs {
      name
      presignedUrl
      rawUrl
    }
  }
`;