import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Button, Grid } from "@mui/material";
import "./component-list.css";
import { LinearProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { GET_ASSEMBLY, GET_ASSEMBLY_TREE } from "gql/queries";
import { useQuery } from "@apollo/client";
import AssemblyModal from "./AssemblyModal";
import { CardHeader, IconButton, Divider } from "@mui/material";
import ComponentCard from "./ComponentCard";
import { makeStyles } from "@mui/styles";
import TreeNode from "./TreeNode";
import MaterialPieChart from "./reports/ByMaterial";

const useStyles = makeStyles({
  root: {
    display: "flex",
    overflowX: "auto",
    whiteSpace: "nowrap",
    backgroundColor: "black",
  },
});

const ScrollTree = ({ assemblyId }: { assemblyId: any }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    refetch();
  };

  const [componentId, setComponentId] = useState<string>("");

  const { data, loading, error, refetch } = useQuery(GET_ASSEMBLY_TREE, {
    variables: { getAssemblyId: assemblyId },
  });
  console.log(data ? data : "No data");

  if (loading) return <LinearProgress />;
  if (error) return <div>{error.message}</div>;

  const components: any[] = data.getAssemblyTree.assembly.components;

  const nodes: any[] = data.getAssemblyTree.nodes;

  const handleButtonclick = () => {
    console.log("Button clicked");
  };

  const openModal = (assemblyId: string, componentId: string) => {
    setComponentId(componentId);
    setOpen(true);
  };

  const findComponent = (id: string) => {
    return components.find((c) => c.id === id);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {nodes.map((node: any) => {
            return (
              <div key={node.id}>
                <div
                  className="TreeRoot"
                  style={{
                    margin: "16px",
                    marginRight: "120px",
                    // marginLeft: "120px",
                  }}
                >
                  <TreeNode
                    node={node}
                    assemblyId={assemblyId}
                    components={components}
                    addChild={openModal}
                    depth={0}
                  />
                </div>
                <Divider />
              </div>
            );
          })}
        </Grid>
      </Grid>
      <AssemblyModal
        open={open}
        handleClose={handleClose}
        componentId={componentId}
        assemblyId={assemblyId}
      />
    </>
  );
};

export default ScrollTree;
