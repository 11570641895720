import React from "react";
import { useHistory } from "react-router-dom";

import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "azure/config";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { authenticatedUserVar } from "apollo/cache";

const Login = () => {
  const { instance } = useMsal();
  const history = useHistory();

  const handleLogin = () => {
    instance
      .loginPopup(tokenRequest)
      .then(async (response) => {
        const user = response.account;
        instance.setActiveAccount(user);
        authenticatedUserVar(user);
        history.push("/dashboard");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row-reverse" justifyContent="center">
          <Button size="large" color="primary" onClick={handleLogin}>
            <Typography variant="h3">
              Login
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
