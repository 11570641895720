import React, { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { GET_ASSEMBLY_TREE } from "gql/queries";
import { useQuery } from "@apollo/client";
import { LinearProgress } from "@mui/material";

const MaterialPieChart = ({ assemblyId }: { assemblyId: string }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const { data, loading, error } = useQuery(GET_ASSEMBLY_TREE, {
    variables: { getAssemblyId: assemblyId },
  });

  useEffect(() => {
    if (loading || error || !data || !chartRef) {
      return;
    }

    const components = data.getAssemblyTree.assembly.components;

    // Assuming your data fetching logic correctly provides an array of components
    const summedByMaterial = components.reduce((acc: any, component: any) => {
      const material = component.product.material.name;
      const carbonCost = component.carbonCost;
      if (acc[material]) {
        acc[material] += carbonCost;
      } else {
        acc[material] = carbonCost;
      }
      return acc;
    }, {});

    const chartData = Object.entries(summedByMaterial).map(([material, carbonCost]) => ({
      material,
      carbonCost,
    }));

    const root = am5.Root.new(chartRef.current!); // Add the non-null assertion operator (!) to chartRef.current

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "carbonCost",
        categoryField: "material",
      }),
    );

    series.data.setAll(chartData);

    // Cleanup
    return () => root.dispose();
  }, [data, loading, error]); // React will optimize out unnecessary re-renders, but it's crucial all these variables are listed to comply with hook dependencies.

  // Handle loading and error states after hook calls
  if (loading) return <LinearProgress />;
  if (error) return <div>Error: {error.message}</div>;

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }}></div>;
};

export default MaterialPieChart;
