import React from "react";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import theme from "theme";
import bannerLogo from "assets/brand/banner-logo.png";
import { useReactiveVar } from "@apollo/client";
import { authenticatedUserVar } from "apollo/cache";
import { clearClient } from "apollo/client";

const Navbar = () => {
  const { instance } = useMsal();
  const history = useHistory();
  const user = useReactiveVar(authenticatedUserVar);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const logout = () => {
    const account = instance.getActiveAccount();
    if (account) {
      instance.logout({
        account: account,
      });
    }
    else {
      console.error("No active account found to logout");
    }
  };

  const onLogoutClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    logout();
    clearClient();
    handleMenuClose();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {user ? (
          <div>
            <IconButton
              aria-label="user profile"
              aria-controls="user-menu"
              color="inherit"
              onClick={handleMenuClick}
            >
              <AccountCircleIcon color="inherit" />
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard" style={{ color: "black" }}>
                  Dashboard
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/assemblies" style={{ color: "black" }}>
                  Assemblies
                </Link>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
            </Menu>
          </div>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
