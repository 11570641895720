// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
  width: 600px;
  margin: 10px;
}
.card-content {
  display: flex;
}
.content-right {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 20%;
}
.modal-form-inputs {
  margin: 10px !important;
}
.form-container {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/newPage/component-list.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".custom-card {\n  width: 600px;\n  margin: 10px;\n}\n.card-content {\n  display: flex;\n}\n.content-right {\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  align-items: center;\n  margin-left: 20%;\n}\n.modal-form-inputs {\n  margin: 10px !important;\n}\n.form-container {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
