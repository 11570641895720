import React from "react";

import { useMutation } from "@apollo/client";
import { DELETE_BLOB } from "gql/mutations";
import { MINT_STORAGE_URLS } from "gql/mutations";

// Filepond Plugin imports
import { FilePond, registerPlugin } from "react-filepond";
import type {
  ActualFileObject,
  ProcessServerConfigFunction,
  RemoveServerConfigFunction,
  RevertServerConfigFunction,
} from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

// FilePond styles
import "filepond/dist/filepond.min.css";

// Register the plugins for usage
registerPlugin(
  FilePondPluginFileValidateType,
);

export type MintStorageUrlsReturn = {
  mintStorageUrls: {
    presignedUrl: string;
    rawUrl: string;
  };
}

export const Upload = () => {
  const filePondRef = React.useRef(null);

  const [mintStorageUrls] = useMutation<MintStorageUrlsReturn>(MINT_STORAGE_URLS);
  const [deleteBlob] = useMutation<boolean>(DELETE_BLOB);

  const process: ProcessServerConfigFunction = async (
    fieldName: string,
    file: ActualFileObject,
    metadata: object,
    load: (p: string) => void,
    error: (errorText: string) => void,
    progress: (isLengthComputable: boolean, loaded: number, total: number) => void,
    abort: () => void,
  ) => {
    const { data } = await mintStorageUrls({
      variables: { blobName: file.name },
    });
    if (!data) {
      error("Error getting presigned URL.");
      return;
    };
    const { presignedUrl, rawUrl } = data.mintStorageUrls;

    const request = new XMLHttpRequest();
    request.open("PUT", presignedUrl);
    request.setRequestHeader("x-ms-blob-type", "BlockBlob");

    // Monitor the upload progress.
    request.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };

    // Handle the upload completion.
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        load(rawUrl);
      } else {
        error("Error uploading file.");
      }
    };

    // Handle upload errors.
    request.onerror = () => {
      error("Error uploading file.");
    };

    // Start the upload.
    request.send(file);

    // Provide a method to abort the upload if needed.
    return {
      abort: () => {
        request.abort();
        error("Upload aborted.");
      },
    };
  };

  const remove: RemoveServerConfigFunction = async (
    source: string,
    load: () => void,
    error: (errorText: string) => void
  ) => {
    const { data } = await deleteBlob({ variables: { url: source } });
    if (data) {
      load();
    } else {
      error("Error removing file.");
    }
  };

  const revert: RevertServerConfigFunction = async (
    uniqueFileId: string, // This is the value populated by load() in the process function
    load: () => void,
    error: (errorText: string) => void
  ) => {
    const { data } = await deleteBlob({ variables: { url: uniqueFileId } });
    if (data) {
      load();
    } else {
      error("Error reverting file.");
    }
  };

  const serverConfig = { 
    process,
    remove,
    revert,
  };

  return (
    <FilePond
      fileSizeBase={1000}
      checkValidity={true}
      // allowFileTypeValidation={true}
      // allowFileSizeValidation={true}
      // allowFileEncode={true}
      chunkUploads={true}
      acceptedFileTypes={[
        "application/pdf",
        "text/html",
        "image/*"
      ]}
      allowMultiple={true}
      // maxFiles={1}
      name="files"
      ref={filePondRef}
      onaddfile={(error, fileItem) => {
        if (error) {
          console.log(error);
        }
        if (fileItem.file.size > 1000000) {
          console.error("File size is too large");
        }
      }}
      server={serverConfig}
      oninit={() => console.log("FilePond instance has initialised")}
      labelIdle="Drag & Drop your files or <span class='filepond--label-action'>Browse</span>"
    />
  );
};
