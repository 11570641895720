import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "components/routes/PrivateRoute";

import { Download } from "components/storage";
import { Navbar } from "components/layout";
import {
  Assemblies,
  Dashboard,
  Assembly,
  Landing,
  Upload,
  Report,
} from "views";

import browserHistory from "browserHistory";

import "./App.css";
import { ComponentList } from "components/newPage";
import ScrollTree from "components/newPage/ScrollTree";
import AssemblyPage from "components/newPage/Create";

const App = () => {
  return (
    <React.Fragment>
      <Router history={browserHistory}>
        <div className="App">
          <Navbar />
          <Switch>
            {/* Auth */}
            <Route exact path="/" component={Landing} />
            {/* Files */}
            <PrivateRoute exact path="/report" component={Report} />
            <PrivateRoute exact path="/download" component={Download} />
            <PrivateRoute exact path="/upload" component={Upload} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/assemblies" component={Assemblies} />
            <PrivateRoute exact path="/assemblies/:id" component={Assembly} />
            <PrivateRoute exact path="/create-assembly" component={AssemblyPage} />
          </Switch>
        </div>
      </Router>
    </React.Fragment>
  );
};
export default App;
