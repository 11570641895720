import React from "react";

import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
// import { LoadingButton } from "@mui/lab";
import { Add, Delete, Error, Save } from "@mui/icons-material";

import { CREATE_ASSEMBLY, DELETE_BLOB, MINT_STORAGE_URLS, UPDATE_ASSEMBLY } from "gql/mutations";
import { useMutation } from "@apollo/client";

import { Upload } from "components/assemblies";
import { AssemblyFormContext } from "contexts";
import { ActualFileObject, FilePondFile } from "filepond";

type Attribute = {
  key: string,
  value: string,
}

export type AssemblyType = {
  id: string
  name: string
  category: string
  attributes: Attribute[]
  storageUrls: string[]
  presignedByStorageUrl: Map<string, string>
}

export type MintStorageUrlsReturn = {
  mintStorageUrls: {
    name: string;
    presignedUrl: string;
    rawUrl: string;
  };
}

export type FileType = {
  file: ActualFileObject;
  name: string;
  presignedUrl: string;
  storageUrl: string;
};

export type CreateAssemblyReturn = {
  createAssembly: {
    id: string;
    blobs: {
      name: string;
      presignedUrl: string
    }[];
  }
};
export type UpdateAssemblyReturn = {
  updateAssembly: {
    id: string;
    blobs: {
      name: string;
      presignedUrl: string
    }[];
  }
};
export type DeleteBlobReturn = {
  deleteBlob: boolean;
}

export const AssemblyForm = ({ assembly }: { assembly?: AssemblyType }) => {

  const initialStorageUrls = assembly?.storageUrls || [];
  const [name, setName] = React.useState(assembly?.name || "");
  
  const [category, setCategory] = React.useState(assembly?.category || "");
  const [attributes, setAttributes] = React.useState<Attribute[]>(assembly?.attributes || []);

  const [addedFiles, setAddedFiles] = React.useState<ActualFileObject[]>([]);
  const [removedUrls, setRemovedUrls] = React.useState<string[]>([]);

  const existingFileSources = React.useMemo(() => {
    return initialStorageUrls.filter((url) => !removedUrls.includes(url)).concat(addedFiles.map((file) => file.name));
  }, [addedFiles, initialStorageUrls, removedUrls]);

  const [createAssembly, { error: createError }] = useMutation<CreateAssemblyReturn>(CREATE_ASSEMBLY);
  const [updateAssembly, { error: updateError }] = useMutation<UpdateAssemblyReturn>(UPDATE_ASSEMBLY);
  const [mintStorageUrls, { error: mintError }] = useMutation<MintStorageUrlsReturn>(MINT_STORAGE_URLS);
  const [deleteBlob, { error: deleteError }] = useMutation<DeleteBlobReturn>(DELETE_BLOB);

  const [loading, setLoading] = React.useState(false);

  const handleCreate = (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    uploadFiles(addedFiles)
      .then((storageUrls) => {
        createAssembly({
          variables: {
            input: {
              name,
              category,
              attributes,
              storageUrls,
            },
          }
        })
          .then(() => setLoading(false))
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleUpdate = (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const mutateFiles = async () => {
      const newStorageUrls = await uploadFiles(addedFiles);
      await deleteFiles(removedUrls);
      return newStorageUrls.concat(initialStorageUrls.filter((url) => !removedUrls.includes(url)));
    };
    mutateFiles()
      .then((storageUrls) => {
        updateAssembly({
          variables: {
            input: {
              id: assembly?.id,
              name,
              category,
              attributes,
              storageUrls,
            },
          }
        })
          .then(() => {
            setAddedFiles([]);
            setRemovedUrls([]);
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const newAttribute = () => {
    setAttributes([
      ...attributes, 
      {
        key: "",
        value: "",
      }
    ]);
  };

  const uploadFiles = async (addedFiles: ActualFileObject[]): Promise<string[]> => {
    const promises = addedFiles.map(async (file) => {
      const urls = await mintStorageUrls({
        variables: {
          blobName: file.name,
        }
      });
      return {
        file: file,
        name: file.name,
        presignedUrl: urls.data?.mintStorageUrls.presignedUrl as string,
        storageUrl: urls.data?.mintStorageUrls.rawUrl as string,
      };
    });
    const filesToUpload = await Promise.all(promises);
    const uploads = filesToUpload.map((file) => {
      return new Promise<string>(function (resolve, reject) {
        if (!file) {
          reject("Error uploading file.");
          return;
        };

        const request = new XMLHttpRequest();
        
        request.open("PUT", file.presignedUrl);
        request.setRequestHeader("x-ms-blob-type", "BlockBlob");

        // Monitor the upload progress.
        // request.upload.onprogress = (e) => {
        //   updateProgress(e.lengthComputable, e.loaded, e.total);
        // };

        // Handle the upload completion.
        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            // The file has been uploaded
            resolve(file.name);
          } else {
            // Handle error
            reject("Error uploading file.");
          }
        };

        // Handle upload errors.
        request.onerror = () => {
          reject("Error uploading file.");
        };

        // Start the upload.
        request.send(file.file);
      });
    });
    await Promise.all(uploads);
    return filesToUpload.map((file) => file.storageUrl);
  };

  const deleteFiles = async (removedUrls: string[]): Promise<void> => {
    const promises = removedUrls.map(async (url) => {
      await deleteBlob({
        variables: { url },
      });
    });
    await Promise.all(promises);
  };

  const updateField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number, field: string) => {
    const { value } = e.target;
    const newAttributes = [...attributes];
    if (field === "key") newAttributes[idx].key = value;
    if (field === "value") newAttributes[idx].value = value;
    setAttributes(newAttributes);
  };

  const error = createError || updateError || mintError || deleteError;

  return (
    <AssemblyFormContext.Provider value={{
      addedFiles,
      removedUrls,
      setAddedFiles,
      setRemovedUrls,
    }}>
      <Container>
        <form onSubmit={assembly ? handleUpdate : handleCreate}>
          <FormControl
            error={!!error}
            fullWidth
          >
            <Box mb={1}>
              <TextField
                fullWidth
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Box>
            <Box mb={1}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  label="Category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <MenuItem value="wall">Wall</MenuItem>
                  <MenuItem value="floor">Floor</MenuItem>
                  {/* Add more categories if needed */}
                </Select>
                <FormHelperText>Select a category</FormHelperText>
              </FormControl>
            </Box>
            <Box mb={2}>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                mb: 1,
              }}>
                <Typography
                  variant="h5"
                  sx={{mr: 1}}
                >
                  Attributed Data
                </Typography>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={newAttribute}
                  endIcon={<Add />}
                >
                  Add
                </Button>
              </Box>
              {
                attributes.map((attribute, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <TextField
                      sx={{width:"34%"}}
                      label="Key"
                      value={attribute.key}
                      onChange={(e) => updateField(e, index, "key")}
                    />
                    <TextField
                      sx={{flexGrow: 1, mr: 1}}
                      label="Value"
                      value={attribute.value}
                      onChange={(e) => updateField(e, index, "value")}
                    />
                    <IconButton
                      size="large"
                      onClick={() => setAttributes(attributes.filter((_, idx) => idx !== index))}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </Box>
                ))
              }
            </Box>
            <Upload
              sources={existingFileSources}
              presignedBySource={assembly?.presignedByStorageUrl || new Map<string, string>}
            />
            {/* <LoadingButton
              fullWidth
              loadingPosition="start"
              variant="contained"
              color={error ? "error" : "primary"}
              type="submit"
              loading={loading}
              startIcon={error ? <Error /> : <Save />}
            >
              { error ? "Error!" : "Save" }
            </LoadingButton> */}
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        </form>
      </Container>
    </AssemblyFormContext.Provider>
  );
};
