import { gql } from "@apollo/client";

export const GET_ASSEMBLY = gql`
  query GetAssembly($getAssemblyId: String!) {
    getAssembly(id: $getAssemblyId) {
      components {
        height
        id
        name
        product {
          height
          name
          thickness
          width
        }
        productQuantity
        thickness
        width
        children {
          height
          id
          name
          product {
            height
            name
            thickness
            width
          }
          productQuantity
          thickness
          width
        }
      }
    }
  }
`;

export const ComponentNodeFragment = gql`
  fragment ComponentNodeFragment on ComponentNode {
    id
    rows
    columns
    children {
      id
      rows
      columns
      children {
        id
        rows
        columns
        children {
          id
          rows
          columns
          children {
            id
            rows
            columns
            children {
              id
              rows
              columns
            }
          }
        }
      }
    }
  }
`;

export const GET_ASSEMBLY_TREE = gql`
  ${ComponentNodeFragment}
  query GetAssemblyTree($getAssemblyId: String!) {
    getAssemblyTree(id: $getAssemblyId) {
      assembly {
        id
        components {
          height
          id
          name
          product {
            height
            name
            thickness
            width
            mass
            carbonCost
            material {
              name
              carbon
            }
          }
          productQuantity
          thickness
          width
          carbonCost
        }
      }
      nodes {
        ...ComponentNodeFragment
      }
    }
  }
`;


export const GET_ALL_ASSEMBLIES = gql`
  query GetAllAssemblies {
    getAssemblies {
      id
      name
      category
    }
  }
`;

export const GET_ADDITION_TYPES = gql`
  query GetAdditionTypes {
    getAdditionTypes
  }
`;