import React from "react";
import { Upload } from "components/storage";

const UploadView = () => {
  return (
    <div
      style={{
        width: "50%",
        margin: "auto",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          fontSize: "40px",
        }}
      >
        Azure File Upload
      </h1>
      <Upload />
    </div>
  );
};

export default UploadView;