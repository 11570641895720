
export const KNOWN_AUTHORITY = `${process.env.REACT_APP_AD_TENANT_NAME}.b2clogin.com`;
export const AUTHORITY = `https://${KNOWN_AUTHORITY}/${process.env.REACT_APP_AD_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AD_USER_FLOW}`;

export const config = {
  auth: {
    clientId: process.env.REACT_APP_AD_SPA_CLIENT_ID as string,
    authority: AUTHORITY,
    knownAuthorities: [KNOWN_AUTHORITY],
    redirectUri: `${process.env.REACT_APP_PUBLIC_URL}/dashboard`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const tokenRequest = {
  scopes: [`https://${process.env.REACT_APP_AD_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_AD_API_CLIENT_NAME}/${process.env.REACT_APP_AD_API_ACCESS_SCOPE}`],
};
