import React from "react";
import { useQuery } from "@apollo/client";
import { AUTH_QUERY } from "gql/queries";
import { Link } from "react-router-dom";

import { Box, Button, LinearProgress, Typography } from "@mui/material";

const Hi = () => {
  const { data, loading, error } = useQuery(AUTH_QUERY);

  if (loading) return <LinearProgress />;
  if (error) return <div>{ error.message }</div>;

  console.log(data);

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "2rem",
      // height: "100%",
    }}>
      <Typography
        variant="h3"
      >
        Hi {data.auth.name}
      </Typography>
      <Button color="primary" size="large">
        <Link to="download">Download</Link>
      </Button>
      <Button color="primary" size="large">
        <Link to="/upload">Upload</Link>
      </Button> 
      <Button color="primary" size="large">
        <Link to="/report">Report</Link>
      </Button> 
      <Button color="primary" size="large">
        <Link to="/create-assembly">Create Assembly</Link>
      </Button>
    </Box>
  );
};

export default Hi;