import React from "react";

// Filepond Plugin imports
import { FilePond, registerPlugin } from "react-filepond";
import type {
  ActualFileObject,
  ProcessServerConfigFunction,
  RemoveServerConfigFunction,
} from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

// FilePond styles
import "filepond/dist/filepond.min.css";
import { AssemblyFormContext } from "contexts";

// Register the plugins for usage
registerPlugin(
  FilePondPluginFileValidateType,
);

export const Upload = ({ presignedBySource, sources }: { presignedBySource: Map<string, string>, sources: string[] }) => {
  const filePondRef = React.useRef(null);
  const assemblyFormContext = React.useContext(AssemblyFormContext);

  const process: ProcessServerConfigFunction = async (
    fieldName: string,
    file: ActualFileObject,
    metadata: object,
    load: (p: string) => void,
    error: (errorText: string) => void,
    progress: (isLengthComputable: boolean, loaded: number, total: number) => void,
    abort: () => void,
  ) => {
    // Register the file to be uploaded
    assemblyFormContext.setAddedFiles((prev) => [...prev, file]);
    load(file.name);
  };

  const remove: RemoveServerConfigFunction = async (
    source: string,
    load: () => void,
    error: (errorText: string) => void
  ) => {
    if (source.includes("blob.core.windows.net")) {
      // If this file came from the server, we need to delete it from the server
      // so register that it needs to be deleted
      assemblyFormContext.setRemovedUrls((prev) => [...prev, source]);
    }
    else {
      // If this file came from the client, we need to remove it from the list
      // of files to be uploaded
      assemblyFormContext.setAddedFiles((prev) => prev.filter((file) => file.name !== source));
    }
    load();
  };

  const serverConfig = { 
    process,
    remove,
  };

  return (
    <FilePond
      fileSizeBase={1000}
      checkValidity={true}
      // allowFileTypeValidation={true}
      // allowFileSizeValidation={true}
      // allowFileEncode={true}
      chunkUploads={true}
      acceptedFileTypes={[
        "application/pdf",
        "text/html",
        "image/*"
      ]}
      allowMultiple={true}
      // maxFiles={1}
      name="files"
      ref={filePondRef}
      files={sources?.map((source) => {
        // This preloads the files that are already on the server
        // but without any file content, only their URL
        return {
          source: source,
          options: {
            type: "local",
          },
        };
      })}
      onaddfile={(error, fileItem) => {
        if (error) {
          console.error(error);
        }
        if (fileItem.file.size > 1000000) {
          console.error("File size is too large");
        }
      }}
      onactivatefile={(file) => {
        if (presignedBySource.get(file.serverId)) {
          window.open(presignedBySource.get(file.serverId), "_blank", "noopener,noreferrer");
        }
        console.log(file);
      }}
      server={serverConfig}
      oninit={() => console.log("FilePond instance has initialised")}
      labelIdle="Drag & Drop your files or <span class='filepond--label-action'>Browse</span>"
    />
  );
};
