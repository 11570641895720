import { ApolloClient, from } from "@apollo/client";
import cache from "apollo/cache";
import { authLink, errorLink, httpLink } from "apollo/links";

const client = new ApolloClient({
  cache,
  link: from([authLink, errorLink, httpLink]),
});

export const clearClient = async () => {
  await cache.reset();
  await client.resetStore();
  await client.clearStore();
};

export default client;
