import { gql } from "@apollo/client";

export const DELETE_BLOB = gql`
  mutation DeleteBlob($url: String!) {
    deleteBlob(url: $url)
  }
`;

export const MINT_STORAGE_URLS = gql`
  mutation MintStorageUrls($blobName: String!) {
    mintStorageUrls(blobName: $blobName) {
      presignedUrl
      rawUrl
    }
  }
`;