import { InMemoryCache, makeVar } from "@apollo/client";
import { AccountInfo } from "@azure/msal-browser";

export const authenticatedUserVar = makeVar<AccountInfo | null>(null);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        authenticatedUser: {
          read() {
            return authenticatedUserVar();
          },
        },
      },
    },
  },
});

export default cache;
