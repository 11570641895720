import React, { useRef } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_MATERIALS_QUERY, GET_ALL_NONMODELED_QUERY } from "gql/queries";
import { Link } from "react-router-dom";

import { Box, Button, LinearProgress, Typography, Divider } from "@mui/material";
import { Materials, NonModeled, Ratios } from "./charts";

const Charts = () => {

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "2rem",
      // height: "100%",
    }}>
      <Typography
        variant="h3"
      >
        <div>Reporting</div>
        <NonModeled/>
        <Divider/>
        <Materials/>
        <Divider/>
        {/* <Ratios/> */}

      </Typography>
    </Box>
  );
};

export default Charts;