import React, { useEffect, useState } from "react";
import { Modal, TextField, Select, MenuItem, Button, Grid, Divider, FormControl, InputLabel, Typography } from "@mui/material";
import { Box } from "@mui/material";
import "./component-list.css";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_PRODUCT } from "gql/mutations";
import { GET_ADDITION_TYPES } from "gql/queries";
import { materials, productProperties, materialValues } from "./materials";


const additionToImage = {
  "Singular": "additionTypes/addCoatComponent.jpg",
  "Linear": "additionTypes/addLinearcomponent.jpg",
  "SpacedLinear": "additionTypes/addSpacedLinearComponent.jpg",
  "SpacedLinearPerComponent": "additionTypes/addSpacedLinearPerComponent.jpg",
  "SpacedLinearPerimeterComponent": "additionTypes/addSpacedLinearPerimeterComponent.jpg",
  "Fill": "additionTypes/addFillComponent.jpg",
  "Squared": "additionTypes/addSquaredComponent.jpg",
  "Coat": "additionTypes/addCoatComponent.jpg",
};




export default function AssemblyModal({ handleClose, open, componentId, assemblyId }: { handleClose: any, open: any, componentId: string, assemblyId: string }) {
  const { data, loading, error } = useQuery(GET_ADDITION_TYPES);
  const [productName, setProductName] = useState("");
  const [productMaterial, setProductMaterial] = useState("Decking");
  const productMaterials = materials.map((material: any) => material.name);

  const [productAdditionType, setProductAdditionType] = useState("Singular");
  const [productHeight, setProductHeight] = useState<number>(0);
  const [productWidth, setProductWidth] = useState<number>(0);
  const [productThickness, setProductThickness] = useState<number>(0);
  const [properties, setProperties] = useState<any>({});
  const [propertyFields, setPropertyFields] = useState<any>([]);


  const [addProduct] = useMutation(ADD_PRODUCT);


  console.log(data ? data : "No data");

  const updateProperties = (properties: any) => {
    const newProperties: any = {};
    properties.forEach((field: any, index: any) => {
      newProperties[field.field] = field.default;
    });

    setProperties(newProperties);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const HandleAddProduct = () => {
    const variables = {
      "input": {
        "assemblyId": assemblyId,
        "componentId": componentId,
        "product": {
          "name": productName,
          "height": productHeight,
          "width": productWidth,
          "thickness": productThickness,
        },
        "type": productAdditionType,
        "properties": properties,
        "material": productMaterial,
      }
    };
    addProduct({ variables });

    handleClose();

  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <h2>Add Product</h2>
        <div className="form-container">
          <Grid container>
            <Grid item xs={4} paddingRight="16px">
              <TextField
                id="outlined-basic"
                label="Product Name"
                fullWidth
                variant="outlined"
                // className="modal-form-inputs"
                onChange={(e) => setProductName(e.target.value)}></TextField>
            </Grid>
            <Grid item xs={4} paddingRight="16px">
              <FormControl fullWidth>
                <InputLabel id="material-label">Material Type</InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  labelId="material-label"
                  value={productMaterial}
                  label="Material Type"
                  onChange={(e: any) => setProductMaterial(e.target.value)}
                // className="modal-form-inputs"
                >
                  {productMaterials.map((material: any, index: any) => {
                    return <MenuItem key={index} value={material}>{material}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} paddingRight="16px">
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={componentId}
                  disabled
                  label="Parent ID"
                  onChange={(e: any) => setProductMaterial(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <h3>Product Relation</h3>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="add-type-label">Approximation Type</InputLabel>
                <Select
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={productAdditionType}
                  label="Approximation Type"
                  onChange={(e: any) => {
                    const selectedValue = e.target.value as keyof typeof productProperties;
                    setPropertyFields(productProperties[selectedValue]);
                    updateProperties(productProperties[selectedValue]);
                    setProductAdditionType(selectedValue);
                  }
                  }
                >
                  {data ? data.getAdditionTypes.map((type: any, index: any) => {
                    return <MenuItem key={index} value={type}>{type}</MenuItem>;
                  }
                  ) : <MenuItem value={""}>None</MenuItem>
                  }
                </Select>
              </FormControl>
              {/* <Typography variant="caption" display="block" gutterBottom>*This determines how the total volume of the component is calculated, given by the size of the product and the type of approximation.</Typography> */}
              {/* <Grid item xs={6}> */}
              {propertyFields.map((field: any, index: any) => {
                if (field.type === "select") {
                  return <>
                    <FormControl fullWidth style={{ marginTop: "16px" }}>
                      <InputLabel id={`properties-${index}-label`}>{field.name}</InputLabel>
                      <Select
                        // style={{ paddingRight: "16px" }}
                        key={index}
                        fullWidth
                        id="outlined-basic"
                        defaultValue={field.default}
                        value={field.value}
                        onChange={(e: any) => {
                          const newProperties = properties;
                          newProperties[field.field] = e.target.value;
                          setProperties(newProperties);
                        }}
                        labelId={`properties-${index}-label`}
                        label={field.name}
                        variant="outlined"
                      >
                        {field.options.map((option: any, index: any) => {
                          return <MenuItem key={index} value={option}>{option}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </>;
                } else if (field.type === "number") {
                  return <TextField
                    style={{ marginTop: "16px" }}
                    key={index}
                    fullWidth
                    type="number"
                    id="outlined-basic"
                    defaultValue={field.default}
                    value={properties[field.name]}
                    onChange={(e: any) => {
                      const newProperties = properties;
                      newProperties[field.field] = parseFloat(e.target.value);
                      setProperties(newProperties);
                    }}
                    label={field.name}
                    variant="outlined"
                  />;
                }

              })}

              {/* </Grid> */}
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} alignContent="left">
              <img src={`/assets/${additionToImage[productAdditionType as keyof typeof additionToImage]}`} alt="Product Image" width={250} height={250} />
            </Grid>
            <Grid item xs={12}>
              <h3>Product Dimensions</h3>
            </Grid>
            <Grid item xs={4} paddingRight="16px">
              <TextField
                fullWidth
                id="outlined-basic"
                value={productHeight}
                type="number"
                label="Product Height  (m)"
                variant="outlined"
                onChange={(e) => setProductHeight(parseFloat(e.target.value))}
                InputProps={{ inputProps: { step: 0.01, min: 0 } }}>

              </TextField>
            </Grid>
            <Grid item xs={4} paddingRight="16px">
              <TextField
                id="outlined-basic"
                fullWidth
                value={productWidth}
                type="number"
                label="Product Width  (m)"
                variant="outlined"
                onChange={(e) => setProductWidth(parseFloat(e.target.value))}
                InputProps={{ inputProps: { step: 0.01, min: 0 } }}>

              </TextField>
            </Grid>
            <Grid item xs={4} paddingRight="16px">
              <TextField
                fullWidth
                id="outlined-basic"
                value={productThickness}
                type="number"
                label="Product Thickness (m)"
                variant="outlined"
                onChange={(e) => setProductThickness(parseFloat(e.target.value))}
                InputProps={{ inputProps: { step: 0.01, min: 0 } }}>
              </TextField>
            </Grid>
            <Grid item xs={4} marginTop="16px" paddingRight="16px">
              <TextField
                fullWidth
                id="outlined-basic"
                value={productThickness * productHeight * productWidth}
                disabled
                label="Product Volume (m³)"
                variant="outlined"
                onChange={(e) => setProductThickness(parseFloat(e.target.value))}
                InputProps={{ inputProps: { step: 0.01, min: 0 } }}>
              </TextField>
            </Grid>
            <Grid item xs={4} marginTop="16px" paddingRight="16px">
              <TextField
                fullWidth
                id="outlined-basic"
                value={productThickness * productHeight * productWidth}
                disabled
                label="Product Mass (kg)"
                variant="outlined"
                onChange={(e) => setProductThickness(parseFloat(e.target.value))}
                InputProps={{ inputProps: { step: 0.01, min: 0 } }}>
              </TextField>
            </Grid>
            <Grid item xs={4} marginTop="16px" paddingRight="16px">
              <TextField
                fullWidth
                id="outlined-basic"
                value={productThickness * productHeight * productWidth * materialValues[productMaterial as keyof typeof materialValues]}
                disabled
                label="Product Carbon (kg CO²eq)"
                variant="outlined"
                onChange={(e) => setProductThickness(parseFloat(e.target.value))}
                InputProps={{ inputProps: { step: 0.01, min: 0 } }}>
              </TextField>
            </Grid>
          </Grid>
          <Button style={{ marginTop: "16px", marginBottom: "-10px" }} className="modal-form-button" onClick={HandleAddProduct}>Add Product</Button>

        </div>

      </Box>
    </Modal >
  );

}
