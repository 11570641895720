import React from "react";
import { useHistory } from "react-router-dom";

import { Box, Container, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "theme";

import { useQuery } from "@apollo/client";
import { GET_ALL_PRESIGNED_URLS } from "gql/queries";

const useStyles = makeStyles(() =>  ({
  root: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(8),
  },
  box: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
  },
  link: {
    marginInline: theme.spacing(8),
    color: theme.palette.primary.light,
  },
}));

export type GetAllPresignedUrlsReturn = {
  getAllBlobs: {
    name: string;
    presignedUrl: string;
    rawUrl: string
  }[];
}

export const Download = () => {
  const classes = useStyles();

  const { data, loading, error }  = useQuery<GetAllPresignedUrlsReturn>(GET_ALL_PRESIGNED_URLS);
  
  if (loading) return <LinearProgress />;
  if (error) return <div>{ error.message }</div>;

  console.log(data);
  return (
    <Container fixed className={classes.root}>
      <Box className={classes.box} component="div">
        <Typography variant="h5">
          <b>Download</b>
          {
            data?.getAllBlobs.map((blob, index) => (
              <Typography key={index}>
                <a
                  href={ blob.presignedUrl }
                  download
                  target="_blank"
                  rel="noreferrer"
                >{ blob.name }</a>
              </Typography>
            ))
          }
        </Typography>
      </Box>
    
    </Container>
  );
};