// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.children {
    /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); */
    /* Creates a new column for each level */
    /* gap: 10px; */
}

/* .node {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.card {
    width: 100%;
    /* border: 1px solid #ccc; */
    /* padding: 10px; */
    /* text-align: center; */
    /* margin-bottom: 10px; */
    /* Space between card and children */
}`, "",{"version":3,"sources":["webpack://./src/components/newPage/TreeNode.css"],"names":[],"mappings":"AAAA;IACI,kEAAkE;IAClE,wCAAwC;IACxC,eAAe;AACnB;;AAEA;;;;GAIG;;AAEH;IACI,WAAW;IACX,4BAA4B;IAC5B,mBAAmB;IACnB,wBAAwB;IACxB,yBAAyB;IACzB,oCAAoC;AACxC","sourcesContent":[".children {\n    /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); */\n    /* Creates a new column for each level */\n    /* gap: 10px; */\n}\n\n/* .node {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n} */\n\n.card {\n    width: 100%;\n    /* border: 1px solid #ccc; */\n    /* padding: 10px; */\n    /* text-align: center; */\n    /* margin-bottom: 10px; */\n    /* Space between card and children */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
