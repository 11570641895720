import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import theme from "theme";
import { authenticatedUserVar } from "apollo/cache";
import { useReactiveVar } from "@apollo/client";

import { Login } from "components/auth";

const useStyles = makeStyles(() => ({
  root: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(8),
  },
  box: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
  },
  link: {
    marginInline: theme.spacing(8),
    color: theme.palette.primary.light,
  },
}));

const Landing = () => {
  const user = useReactiveVar(authenticatedUserVar);
  const classes = useStyles();

  return (
    <Container fixed className={classes.root}>
      <Box className={classes.box} component="div">
        <Typography variant="h5">
          <b>
            AmeriCan Structures Web-app
          </b>
        </Typography>
      </Box>
      {/* <Box className={classes.box} component="div">
        <Typography variant="h6">
          Run and save comparisons. Quantify the impact of design choices.
        </Typography>
      </Box>
      <Box className={classes.box} component="div">
        <Typography variant="h6">Build reports and graphics to share.</Typography>
      </Box> */}
      <Box component="div" className={classes.box} marginTop={theme.spacing(1)}>
        <Login />
      </Box>
    </Container>
  );
};

export default Landing;
