import { createContext } from "react";
import { ActualFileObject } from "filepond";

export const AssemblyFormContext = createContext<{
  addedFiles: ActualFileObject[],
  removedUrls: string[],
  setAddedFiles: React.Dispatch<React.SetStateAction<ActualFileObject[]>>
  setRemovedUrls: React.Dispatch<React.SetStateAction<string[]>>,
}>({
  addedFiles: [],
  removedUrls: [],
  setAddedFiles: () => { return; },
  setRemovedUrls: () => { return; },
});