import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Button, Accordion, AccordionSummary, AccordionDetails, TextField, Grid, AccordionActions } from "@mui/material";
import "./component-list.css";
import { LinearProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { GET_ASSEMBLY } from "gql/queries";
import { useQuery } from "@apollo/client";
import AssemblyModal from "./AssemblyModal";
import { CardHeader, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ComponentCard = ({
  component,
  assemblyId,
  depth,
  addChild,
}: {
  component: any;
  assemblyId: string;
  depth: number;
  addChild: (assemblyId: string, componentId: string) => void;
  // children: React.ReactNode;
}) => {
  return (
    <Accordion className="custom-card" style={{ marginLeft: `${64 * depth}px` }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {component.name}
        <span style={{ marginLeft: "auto" }}>{Math.floor(component.carbonCost)}kg CO²/eq</span>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField disabled label="Material" value={component.product.material.name} />
          </Grid>
          <Grid item xs={3}>
            <TextField disabled label="Product Quantity" value={component.productQuantity} />
          </Grid>
          <Grid item xs={5}>
            <TextField
              disabled
              label="Carbon / Product"
              value={`${Math.floor(component.product.carbonCost)}kg CO²/eq`}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField disabled label="Product Height" value={`${component.product.height} m`} />
          </Grid>
          <Grid item xs={4}>
            <TextField disabled label="Product Width" value={`${component.product.width} m`} />
          </Grid>
          <Grid item xs={4}>
            <TextField disabled label="Product Depth" value={`${component.product.thickness} m`} />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        {/* <Button>onClick={() => deleteComponent(assemblyId, component.id)}></Button> */}
        <Button onClick={() => addChild(assemblyId, component.id)}>Add Child Component</Button>
      </AccordionActions>
    </Accordion>
  );
};

export default ComponentCard;