import React from "react";
import { useParams } from "react-router-dom";

import { AssemblyForm, AssemblyType } from "components/assemblies";
import { Box, LinearProgress, Typography } from "@mui/material";

import { useLazyQuery } from "@apollo/client";
import { GET_ASSEMBLY } from "gql/queries";

type GetAssemblyReturn = {
  getAssembly: {
    id: string;
    name: string;
    category: string;
    attributes: {
      key: string
      value: string
    }[]
    blobs: {
      name: string;
      presignedUrl: string;
      rawUrl: string;
    }[]
  }
}

const Assembly = () => {
  const { id } = useParams<{ id: string }>();
  const isNew = id === "new";

  const [getAssembly, { data, loading, error }] = useLazyQuery<GetAssemblyReturn>(GET_ASSEMBLY, {
    variables: { id: id },
  });

  React.useEffect(() => {
    if (isNew) return;
    getAssembly();
  }, [id]);

  if (loading) return <LinearProgress />;
  if (error) return <div>{ error.message }</div>;

  const presignedByStorageUrl = new Map<string, string>();
  data?.getAssembly.blobs.forEach((blob) => {
    presignedByStorageUrl.set(blob.rawUrl, blob.presignedUrl);
  });
  const assembly = {
    ...data?.getAssembly,
    attributes: data?.getAssembly.attributes.map((attribute) => ({ // remove __typename
      key: attribute.key,
      value: attribute.value,
    })),
    storageUrls: data?.getAssembly.blobs.map((blob) => blob.rawUrl),
    presignedByStorageUrl: presignedByStorageUrl,
  } as AssemblyType;

  return (
    <Box
      sx={{
        width: "50%",
        margin: "auto",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          my: 2,
        }}
        variant="h2"
      >
        { isNew ? "Create " : "Update " } Assembly
      </Typography>
      <AssemblyForm assembly={assembly} />
    </Box>
  );
};

export default Assembly;