import React, { useEffect, useRef, useLayoutEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_NONMODELED_QUERY } from "gql/queries";
import { Link } from "react-router-dom";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { Box, LinearProgress, Typography } from "@mui/material";

const NonModeled = () => {
  const { data, loading, error } = useQuery(GET_ALL_NONMODELED_QUERY);

  useLayoutEffect(() => {

    if (!data) return;
    console.log(data);

    const chartData = data.getNonmodeled.map((item: any) => {
      return {
        country: item.name,
        value: item.carbon.value,
      };
    });

    const root = am5.Root.new("chartdiv2");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        paddingLeft: 0,
        paddingRight: 1,
      })
    );

    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });

    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      disabled: true, // Hide x labels
      fontSize: 12,
    });

    xRenderer.grid.template.setAll({
      location: 1
    });

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0.3,
      categoryField: "country",
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    // Create Y-axis
    const yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    });

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      renderer: yRenderer
    }));

    const series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Series 1",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      sequencedInterpolation: true,
      categoryXField: "country",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    series.data.setAll(chartData);
    xAxis.data.setAll(chartData);


    return () => {
      root.dispose();
    };
  }, [data]);

  if (loading) return <LinearProgress />;
  if (error) return <div>{error.message}</div>;



  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "2rem",
      width: "100%",
      // height: "100%",
    }}>
      <Typography
        variant="h3"
      >
        NonModeled
        <div
          style={{
            width: "800px",
            height: "500px",
          }}
          id="chartdiv2"></div>
      </Typography>
    </Box>
  );
};

export default NonModeled;

