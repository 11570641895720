import React from "react";
import ReactDOM from "react-dom";

import "index.css";
import App from "App";

import { ApolloProvider } from "@apollo/client";
import client from "apollo/client";

import { MsalProvider } from "@azure/msal-react";
import instance from "azure/msal";

import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "theme";

import * as serviceWorker from "serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={instance}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
