import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C60C46",
      dark: "#600622",
      light: "#F33F77",
    },
    secondary: {
      main: "#00695c",
      dark: "#003d33",
      light: "#439889",
    },
  },
  typography: {
    fontFamily: ["Raleway"].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});

export default theme;

// declare module '@mui/material/styles' {
//   interface Theme {
//     palette: {
//       primary: {
//         main: string;
//         dark: string;
//         light: string;
//       },
//       secondary: {
//         main: string;
//         dark: string;
//         light: string;
//       }
//     },
//     typography: {
//       fontFamily: string;
//       fontSize: number;
//       fontWeightLight: number;
//       fontWeightRegular: number;
//       fontWeightMedium: number;
//       fontWeightBold: number;
//     }
//   }
// }
