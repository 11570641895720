import { setContext } from "@apollo/client/link/context";
import { tokenRequest } from "azure/config";
import msalInstance from "azure/msal";
import browserHistory from "browserHistory";

const getAccessToken = async () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    // Handle the case when there"s no active account
    return null;
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...tokenRequest,
      account: account,
    });
    return `Bearer ${response.accessToken}`;
  } catch (error) {
    browserHistory.push("/");
  }
};

export const authLink = setContext(async (_, { headers }) => {
  return {
    // you can set your headers directly here based on the new token
    headers: {
      ...headers,
      Authorization: await getAccessToken(),
    },
  };
});
