export const productProperties = {
  "Singular": [],
  "Linear": [
    {
      name: "Dimension",
      field: "dimension",
      type: "select",
      options: ["height", "width", "thickness"],
      default: "height"
    }
  ],
  "SpacedLinear": [
    {
      name: "Dimension",
      field: "dimension",
      type: "select",
      options: ["height", "width", "thickness"],
      default: "height"
    },
    {
      name: "Spacing (m)",
      field: "spacing",
      type: "number",
      default: 0.2
    }
  ],
  "SpacedLinearPerComponent": [
    {
      name: "Dimension",
      field: "dimension",
      type: "select",
      options: ["height", "width", "thickness"],
      default: "height"
    },
    {
      name: "Spacing (m)",
      field: "spacing",
      type: "number",
      default: 0.2
    }
  ],
  "SpacedLinearPerimeterComponent": [
    {
      name: "Dimensions",
      field: "dimensions",
      type: "select",
      options: ["height & width", "height & thickness", "width & thickness"],
      default: "height & width"
    },
    {
      name: "Spacing (m)",
      field: "spacing",
      type: "number",
      default: 0.2
    }
  ],
  "Fill": [
    {
      name: "Height Fill %",
      field: "height",
      type: "number",
      default: "1"
    },
    {
      name: "Width Fill %",
      field: "width",
      type: "number",
      default: "1"
    },
    {
      name: "Thickness Fill %",
      field: "thickness",
      type: "number",
      default: "1"
    },
  ],
  "Squared": [
    {
      name: "Dimensions",
      field: "dimensions",
      type: "select",
      options: ["height & width", "height & thickness", "width & thickness"],
      default: "height & width"
    },
  ],
  "Coat": [],
};
export const materials = [
  {
    "name": "Decking",
    "value": 0.43
  },
  {
    "name": "Decking Paint",
    "value": 8
  },
  {
    "name": "LVL",
    "value": 0.66
  },
  {
    "name": "SPF",
    "value": 0.14
  },
  {
    "name": "Strapping",
    "value": 1.9
  },
  {
    "name": "Cladding",
    "value": 3.6
  },
  {
    "name": "TW-S",
    "value": 1.9
  },
  {
    "name": "Extruded Aluminium Channel",
    "value": 6.1
  },
  {
    "name": "Zip System Sheating Panels",
    "value": 0.58
  },
  {
    "name": "Zip System tape",
    "value": 0.64
  },
  {
    "name": "Zip System nails",
    "value": 1.9
  }
];
export const materialValues = {
  "Decking": 0.43,
  "Decking Paint": 8,
  "LVL": 0.66,
  "SPF": 0.14,
  "Strapping": 1.9,
  "Cladding": 3.6,
  "TW-S": 1.9,
  "Extruded Aluminium Channel": 6.1,
  "Zip System Sheating Panels": 0.58,
  "Zip System tape": 0.64,
  "Zip System nails": 1.9,
};
