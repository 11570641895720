import { gql } from "@apollo/client";

export const CREATE_ASSEMBLY = gql`
  mutation CreateAssembly($input: CreateAssemblyInput!) {
    createAssembly(input: $input) {
      id
    }
  }
`;

export const UPDATE_ASSEMBLY = gql`
  mutation UpdateAssembly($input: UpdateAssemblyInput!) {
    updateAssembly(input: $input) {
      id
      blobs {
        name
        presignedUrl
      }
    }
  }
`;

export const ADD_PRODUCT = gql`
  mutation AddProduct($input: AddProduct!) {
    addProduct(input: $input) {
      components {
        height
        id
        name
        product {
          height
          name
          thickness
          width
        }
      }
    }
  }
`;