import React from "react";
import { Link, useHistory } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { GET_ALL_ASSEMBLIES } from "gql/queries";
import { 
  Card,
  Box,
  LinearProgress,
  List,
  Typography,
  Button
} from "@mui/material";

type GetAssembliesReturn = {
  getAssemblies: {
    id: string;
    name: string;
    category: string;
  }[]
}

const Assemblies = () => {
  const history = useHistory();

  const { data, loading, error } = useQuery<GetAssembliesReturn>(GET_ALL_ASSEMBLIES);

  if (loading) return <LinearProgress />;
  if (error) return <div>{ error.message }</div>;

  return (
    <Box
      sx={{
        width: "50%",
        margin: "auto",
        marginTop: "2rem",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
        }}
        variant="h2"
      >
        Assemblies
      </Typography>
      <List
        sx={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        { data?.getAssemblies.map((assembly) => (
          <Card
            key={assembly.id}
            sx={{
              width: "100%",
              padding: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Link
              to={`/assemblies/${assembly.id}`}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Typography variant="h4">{assembly.name}</Typography>
              <Typography variant="h5">{assembly.category}</Typography>
            </Link>
          </Card>
        )) }
      </List>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => history.push("/assemblies/new")}
      >
        Create New Assembly
      </Button>
    </Box>
  );
};

export default Assemblies;