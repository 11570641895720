import { gql } from "@apollo/client";

export const GET_ALL_MATERIALS_QUERY = gql`
  query GetAllMaterials {
    getMaterials {
        carbon {
          representation
          unit
          value
        }
        name
      }
    }
`;

export const GET_ALL_NONMODELED_QUERY = gql`
    query GetAllNonmodeled {
        getNonmodeled {
        name
        carbon {
            representation
            unit
            value
        }
        ratios {
          value
            material {
            name
            }
        }
        }
    }
`;